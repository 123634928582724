import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Section from "../../components/Section"
import { Body, Header2 } from "../../components/Typography"

import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import Button from "../../components/Button"

const ProfileGrid = styled.div`
  display: grid;
  gap: 32px;
  padding-top: 32px;
  max-width: 816px;
  align-items: start;
  margin: 0 auto;

  grid-template-columns: 1fr 2fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  max-width: 816px;
  margin: 0 auto;
`

function BobPage({ data }) {
  return (
    <Layout pageTitle="About">
      <Section>
        <ButtonRow>
          <Button url="/about/travis">
            <FiArrowLeft /> Previous Profile
          </Button>
          <Button url="/about/arnie">
            Next Profile <FiArrowRight />
          </Button>
        </ButtonRow>
        <ProfileGrid>
          <GatsbyImage
            image={data.bob.nodes[0].childImageSharp.gatsbyImageData}
          />
          <div>
            <Header2>Bob Finnell</Header2>
            <br />
            <Body>
              Bob has over 30 years of experience counseling rapidly growing
              private and publicly held technology companies. He has served as
              Chief Legal Officer of Calypso, General Counsel of Ventaso and as
              Chief Legal Officer & General Counsel of PeopleSoft. Prior to
              PeopleSoft, he held national and international legal roles at
              Ingres and The Wollongong Group.
              <br />
              <br />
              Bob has expertise in managing the entire range of legal affairs
              including complex technology and services transactions, strategic
              business development and marketing partnerships, merger,
              acquisition and divestitures, financing initiatives, employment
              practices issues, dispute resolution and litigation, corporate
              governance and risk management policies, reporting obligations, IP
              protection, real estate transactions, immigration oversight,
              external counsel management, and international trade issues.
            </Body>
          </div>
        </ProfileGrid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    bob: allFile(filter: { relativePath: { eq: "bob.png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default BobPage
